export async function shortenUrl(idToken, targetUrl, key) {
  let response = await fetch(API_GATEWAY_ENDPOINT_URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': idToken
    },
    body: JSON.stringify({ targetUrl: targetUrl, key: key }),
  });
  let data = await response.json();
  if (response.ok) {
    return `${WEBSITE_URL}/${API_GATEWAY_RESOURCE_PATH}/${data['key']}`;
  } else {
    console.log(`Error status: ${response.status})`);
    throw data;
  }
};
