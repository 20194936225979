import { AuthenticationDetails, CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

const userPool = new CognitoUserPool({
  UserPoolId: COGNITO_USER_POOL_ID,
  ClientId: COGNITO_USER_POOL_CLIENT_ID
});

export function signedIn() {
  return (getIdToken() != null);
};

export function getIdToken() {
  let idToken;
  const cognitoUser = userPool.getCurrentUser();
  if (!cognitoUser) {
    console.log('No current user available! Please sign in.');
    return;
  }
  cognitoUser.getSession((error, session) => {
    if (!error) {
      idToken = session.getIdToken().getJwtToken();
    } else {
      console.log('No session available!');
      console.error(error);
      return;
    }
  });
  return idToken;
};

export function signin(username, password, callback) {
  var authenticationDetails = new AuthenticationDetails({
    Username: username, Password: password
  });
  var cognitoUser = new CognitoUser({
    Username: username, Pool: userPool
  });
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: function(result) {
      callback(true);
    },
    onFailure: function(error) {
      console.error(error);
      callback(false);
    }
  });
};

export function signout() {
  const cognitoUser = userPool.getCurrentUser();
  cognitoUser.signOut();
};
