import './style.scss';
import { signedIn, getIdToken, signin, signout } from './auth.js';
import { shortenUrl } from './api.js';
import * as ClipboardJS from 'clipboard';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/alert';

function dismissibleAlert(message, type) {
  return `
<div class="alert alert-${type} alert-dismissible fade show" role="alert">
  ${message}
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>`
}

function setLinkUrl(url, loading) {
  const shortLink = document.querySelector('#short-url a#link');
  shortLink.href = url;
  shortLink.innerText = url;

  const shortUrlLoaded = document.querySelector('#short-url #loaded');
  const shortUrlLoading = document.querySelector('#short-url #loading');
  shortUrlLoaded.style.display = (loading || url == '') ? 'none' : 'block';
  shortUrlLoading.style.display = loading ? 'inline-block' : 'none';
}

function updateUserInterface(isSignedIn) {
  const signedOut = document.querySelector('#signed-out');
  const signedIn = document.querySelector('#signed-in');

  if (isSignedIn) {
    signedOut.style.display = 'none';
    signedIn.style.display = 'block';
    setLinkUrl('', false);
  } else {
    signedOut.style.display = 'block';
    signedIn.style.display = 'none';
  };
};

window.addEventListener('DOMContentLoaded', (event) => {
  new ClipboardJS('.btn');
  updateUserInterface(signedIn());

  const shortenForm = document.querySelector('form#shorten');

  shortenForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const button = shortenForm.querySelector('button');
    button.disabled = true;
    setLinkUrl('', true);

    const targetUrl = shortenForm.querySelector("input[name='targetUrl']").value;
    const key = shortenForm.querySelector("input[name='key']").value;
    const idToken = getIdToken();
    if (idToken) {
      shortenUrl(idToken, targetUrl, key)
        .then(shortUrl => {
          setLinkUrl(shortUrl, false);
          button.disabled = false;
        })
        .catch((error) => {
          shortenForm.insertAdjacentHTML(
            'beforebegin',
            dismissibleAlert(error.message, 'warning')
          );
          button.disabled = false;
          setLinkUrl('', false);
        });
    } else {
      console.log('Session expired; please sign in again!')
      button.disabled = false;
      updateUserInterface(false);
    }
  });

  const signinForm = document.querySelector('form#signin');

  signinForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const button = signinForm.querySelector('button');
    button.disabled = true;

    const username = signinForm.querySelector("input[name='username']").value;
    const password = signinForm.querySelector("input[name='password']").value;

    signin(username, password, (success) => {
      if (success) {
        console.log('Signing in succeeded!');
        updateUserInterface(true);
      } else {
        console.log('Signing in failed!');
        signinForm.insertAdjacentHTML(
          'beforebegin',
          dismissibleAlert('Signing in failed!', 'warning')
        );
      };
      button.disabled = false;
    });
  });

  const signoutLink = document.querySelector('a#signout');

  signoutLink.addEventListener('click', (e) => {
    e.preventDefault();

    signout();
    console.log('Signing out succeeded!');
    updateUserInterface(false);
  });
});
